import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box,
  Container,
  Heading,
  Text,
  VStack,
  Code,
  Button,
  useClipboard,
  Alert,
  AlertIcon,
  Link,
  Input,
  Select,
  useToast,
  SimpleGrid,
  Icon, 
  Divider,
  useColorModeValue,
  FormControl,
  FormLabel,
  FormHelperText,
  Textarea,
  Stat,
  StatLabel,
  StatNumber,
  StatHelpText,
  HStack,
  IconButton,
} from '@chakra-ui/react';
import { FiCopy, FiSend, FiInfo, FiCheck, FiArrowUpRight, FiPlus, FiList, FiUser } from 'react-icons/fi';
import { useAuth } from '../contexts/AuthContext';
import TestLoggerSection from './TestLoggerSection';
import Footer from './Footer';
import FormCard from './Dashboard/FormCard';
import { UpgradeBanner } from './UpgradeBanner';
const { logontg } = require('logontg');


function Dashboard() {
  const navigate = useNavigate();
  const { user } = useAuth();
  const { hasCopied, onCopy } = useClipboard(user?.apiKey || '');

  const [testMessage, setTestMessage] = useState('');
  const [logLevel, setLogLevel] = useState('info');
  const [isLoading, setIsLoading] = useState(false);
  const toast = useToast();

  const connectionStatus = user?.telegramId 
    ? '✅ Connected to Telegram'
    : '❌ Not connected to Telegram';

  const botCommand = `/connect ${user?.apiKey || 'YOUR_API_KEY'}`;
  const { hasCopied: hasCommandCopied, onCopy: onCommandCopy } = useClipboard(botCommand);

  const [subscriptionData, setSubscriptionData] = useState(null);
  const [isLoadingUsage, setIsLoadingUsage] = useState(true);
  const [error, setError] = useState(null);

  const fetchSubscriptionData = async () => {
    try {
      setError(null);
      const response = await fetch('https://sruve.com/api/subscription/usage', {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const data = await response.json();
      
      if (!response.ok) {
        throw new Error(data.error || 'Failed to fetch subscription data');
      }
      
      setSubscriptionData(data);
    } catch (error) {
      console.error('Error:', error);
      setError(error.message);
    } finally {
      setIsLoadingUsage(false);
    }
  };

  useEffect(() => {
    fetchSubscriptionData();
    const interval = setInterval(fetchSubscriptionData, 60000);
    return () => clearInterval(interval);
  }, []);

  const handleTestLog = async () => {
    if (!user?.telegramId) {
      toast({
        title: 'Error',
        description: 'Please connect your Telegram account first',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    if (!testMessage.trim()) {
      toast({
        title: 'Error',
        description: 'Please enter a message to log',
        status: 'error',
        duration: 3000,
      });
      return;
    }

    setIsLoading(true);
    try {
      const logger = new logontg(
        user.apiKey,
        false,
        process.env.REACT_APP_API_URL || 'https://sruve.com/api',
        true
      );
      
      switch (logLevel) {
        case 'error':
          await logger.error(testMessage);
          break;
        case 'warning':
          await logger.warn(testMessage);
          break;
        case 'debug':
          await logger.debug(testMessage);
          break;
        default:
          await logger.log(testMessage);
      }

      toast({
        title: 'Success',
        description: 'Log message sent successfully!',
        status: 'success',
        duration: 3000,
      });
      setTestMessage('');
    } catch (error) {
      console.error('Error details:', error);
      
      if (error.response?.status === 429) {
        toast({
          title: 'Rate Limit Exceeded',
          description: 'You have reached your tier\'s API request limit. Please upgrade your plan to continue using the API.',
          status: 'warning', 
          duration: 5000,
          isClosable: true
        });
      } else {
        toast({
          title: 'Error',
          description: error.message || 'Failed to send log message',
          status: 'error',
          duration: 3000,
        });
      }
    } finally {
      setIsLoading(false);
    }
  };

  const bgColor = useColorModeValue('white', 'gray.700');
  const borderColor = useColorModeValue('gray.200', 'gray.600');
  const codeBg = useColorModeValue('gray.50', 'gray.800');

  const DashboardCard = ({ title, children }) => (
    <Box
      p={[4, 5, 6]}
      shadow="md"
      borderWidth="1px"
      borderRadius="lg"
      borderColor={borderColor}
      bg={bgColor}
      width="100%"
      transition="all 0.2s"
      _hover={{ shadow: 'lg' }}
    >
      <Heading size="md" mb={4}>{title}</Heading>
      {children}
    </Box>
  );

  const CodeBlock = ({ children }) => {
    const [copied, setCopied] = useState(false);

    const copyCode = async () => {
      await navigator.clipboard.writeText(children);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    };

    return (
      <Box position="relative" my={2}>
        <Button
          position="absolute"
          right={2}
          top={2}
          size="sm"
          variant="ghost"
          onClick={copyCode}
          color="gray.500"
          _hover={{ color: 'gray.200', bg: 'gray.700' }}
        >
          <Icon as={copied ? FiCheck : FiCopy} />
        </Button>
        <Code
          p={4}
          borderRadius="md"
          bg={codeBg}
          display="block"
          whiteSpace="pre"
          overflowX="auto"
          fontSize={['sm', 'md']}
          position="relative"
        >
          {children}
        </Code>
      </Box>
    );
  };

  const CustomAlert = ({ children }) => (
    <Alert
      status="info"
      variant="left-accent"
      borderRadius="md"
      display="flex"
      alignItems="flex-start"
      gap={3}
    >
      <AlertIcon mt={1} />
      <Box>
        {children}
      </Box>
    </Alert>
  );

  const handleUpgrade = async () => {
    try {
      const response = await fetch('https://sruve.com/api/subscription/checkout', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        }
      });
      const data = await response.json();
      window.location.href = data.checkoutUrl;
    } catch (error) {
      toast({
        title: 'Error',
        description: error.message || 'Failed to initiate upgrade',
        status: 'error',
        duration: 3000
      });
    }
  };

  return (
    <Box minH="100vh" display="flex" flexDirection="column">
      <Container maxW="container.xl" py={[4, 6, 10]} flex="1">
        <HStack justify="space-between" mb={8}>
          <Heading size="lg">Dashboard</Heading>
          <IconButton
            icon={<FiUser />}
            variant="ghost"
            onClick={() => navigate('/settings/profile')}
            aria-label="Profile Settings"
          />
        </HStack>
        <VStack spacing={[4, 6, 8]} align="stretch">
          <Alert
            status={user?.telegramId ? 'success' : 'warning'}
            borderRadius="md"
            variant="left-accent"
          >
            <AlertIcon />
            {connectionStatus}
          </Alert>

          <Alert 
            status="info" 
            variant="left-accent"
            borderRadius="md"
          >
            <AlertIcon />
            <Box>
              <Text fontWeight="medium">Statistics Coming Soon</Text>
              <Text fontSize="sm">
                We're working on improving our statistics dashboard. Check back later!
              </Text>
            </Box>
          </Alert>

          <SimpleGrid columns={[1, null, 2]} spacing={[4, 6, 8]}>
            <DashboardCard title="Your API Key">
              <Code
                p={4}
                borderRadius="md"
                bg={codeBg}
                display="block"
                mb={3}
                fontSize={['sm', 'md']}
                overflowX="auto"
              >
                {user?.apiKey}
              </Code>
              <Button
                leftIcon={<Icon as={FiCopy} />}
                onClick={onCopy}
                size="sm"
                colorScheme="blue"
                variant="outline"
              >
                {hasCopied ? 'Copied!' : 'Copy API Key'}
              </Button>
            </DashboardCard>

            <FormCard />

            {!user?.telegramId && (
              <DashboardCard title="Connect to Telegram">
                <VStack align="stretch" spacing={4}>
                  <Text>
                    1. Open our Telegram bot:{' '}
                    <Link href="https://t.me/LogonTG_bot" color="blue.500" isExternal>
                      @LogonTG_bot
                    </Link>
                  </Text>
                  <Text>2. Send this command to the bot:</Text>
                  <Code
                    p={4}
                    borderRadius="md"
                    bg={codeBg}
                    display="block"
                    fontSize={['sm', 'md']}
                    overflowX="auto"
                  >
                    {botCommand}
                  </Code>
                  <Button
                    leftIcon={<Icon as={FiCopy} />}
                    onClick={onCommandCopy}
                    size="sm"
                    width="fit-content"
                    colorScheme="blue"
                    variant="outline"
                  >
                    {hasCommandCopied ? 'Copied!' : 'Copy Command'}
                  </Button>
                </VStack>
              </DashboardCard>
            )}
          </SimpleGrid>

          <TestLoggerSection
            user={user}
            testMessage={testMessage}
            setTestMessage={setTestMessage}
            logLevel={logLevel}
            setLogLevel={setLogLevel}
            isLoading={isLoading}
            handleTestLog={handleTestLog}
            bgColor={useColorModeValue('gray.50', 'gray.700')}
          />

          <DashboardCard title="Integration Guide">
            <VStack spacing={6} align="stretch">
              <Box>
                <Text fontSize="lg" fontWeight="medium" mb={2}>
                  Quick Start Guide
                </Text>
                <Text mb={4} color="gray.600">
                  Follow these steps to integrate logging into your application:
                </Text>
              </Box>

              <Box>
                <HStack justify="space-between" mb={2}>
                  <Text fontSize="md" fontWeight="medium">1. Installation</Text>
                  <Button
                    size="sm"
                    leftIcon={<Icon as={FiCopy} />}
                    variant="ghost"
                    onClick={() => {
                      navigator.clipboard.writeText('npm install logontg');
                      toast({
                        title: 'Copied!',
                        status: 'success',
                        duration: 2000,
                      });
                    }}
                  >
                    Copy
                  </Button>
                </HStack>
                <Box
                  bg={useColorModeValue('gray.50', 'gray.800')}
                  p={4}
                  borderRadius="md"
                  fontFamily="mono"
                  fontSize="sm"
                  position="relative"
                  borderWidth="1px"
                  borderColor={useColorModeValue('gray.200', 'gray.600')}
                >
                  npm install logontg
                </Box>
              </Box>

              <Box>
                <HStack justify="space-between" mb={2}>
                  <Text fontSize="md" fontWeight="medium">2. Basic Usage</Text>
                  <Button
                    size="sm"
                    leftIcon={<Icon as={FiCopy} />}
                    variant="ghost"
                    onClick={() => {
                      const code = `// Initialize the logger
import { logontg } from 'logontg';

const logger = new logontg('${user?.apiKey || 'YOUR_API_KEY'}');

// Basic logging
logger.log('Application started successfully');

// Error logging
try {
  // Your code here
} catch (error) {
  logger.error('An error occurred: ' + error.message);
}

// Different log levels
logger.warn('Resource usage is high');
logger.debug('Debug information');

// Object logging
logger.log({
  event: 'user_action',
  userId: 123,
  action: 'profile_update',
  timestamp: new Date()
});`;
                      navigator.clipboard.writeText(code);
                      toast({
                        title: 'Code copied!',
                        status: 'success',
                        duration: 2000,
                      });
                    }}
                  >
                    Copy
                  </Button>
                </HStack>
                <Box
                  bg={useColorModeValue('gray.50', 'gray.800')}
                  p={4}
                  borderRadius="md"
                  fontFamily="mono"
                  fontSize="sm"
                  whiteSpace="pre"
                  overflowX="auto"
                  borderWidth="1px"
                  borderColor={useColorModeValue('gray.200', 'gray.600')}
                  sx={{
                    '&::-webkit-scrollbar': {
                      height: '8px',
                    },
                    '&::-webkit-scrollbar-track': {
                      bg: useColorModeValue('gray.100', 'gray.700'),
                    },
                    '&::-webkit-scrollbar-thumb': {
                      bg: useColorModeValue('gray.300', 'gray.600'),
                      borderRadius: 'full',
                    },
                  }}
                >
{`// Initialize the logger
import { logontg } from 'logontg';

const logger = new logontg('${user?.apiKey || 'YOUR_API_KEY'}', {
  uptime: true
});

// Basic logging
logger.log('Application started successfully');

// Error logging
try {
  // Your code here
} catch (error) {
  logger.error('An error occurred: ' + error.message);
}

// Different log levels
logger.warn('Resource usage is high');
logger.debug('Debug information');

// Object logging
logger.log({
  event: 'user_action',
  userId: 123,
  action: 'profile_update',
  timestamp: new Date()
});`}
                </Box>
              </Box>
            </VStack>
          </DashboardCard>
        </VStack>
      </Container>
      <Footer />
      {subscriptionData && subscriptionData.subscription?.plan !== 'pro' && (
        <UpgradeBanner 
          onUpgrade={async () => {
            const response = await fetch('https://sruve.com/api/subscription/checkout', {
              method: 'POST',
              headers: {
                'Authorization': `Bearer ${localStorage.getItem('token')}`,
              }
            });
            const data = await response.json();
            window.location.href = data.checkoutUrl;
          }} 
        />
      )}
    </Box>
  );
}

export default Dashboard;