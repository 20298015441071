import React from 'react';
import { VStack, HStack, Icon, useColorModeValue } from '@chakra-ui/react';
import { FiStar } from 'react-icons/fi';
import BaseInput from './BaseInput';

export default function RatingInput({ field, onChange, value, error }) {
  const maxRating = field.properties?.maxRating || 5;
  const starColor = useColorModeValue('yellow.400', 'yellow.200');
  const hoverColor = useColorModeValue('yellow.500', 'yellow.300');

  return (
    <BaseInput
      label={field.label}
      isRequired={field.required}
    >
      <HStack spacing={2}>
        {[...Array(maxRating)].map((_, index) => (
          <Icon
            key={index}
            as={FiStar}
            w={6}
            h={6}
            cursor="pointer"
            fill={index < value ? starColor : 'transparent'}
            color={index < value ? starColor : 'gray.300'}
            onClick={() => onChange(field.id, index + 1)}
            _hover={{
              color: hoverColor,
              transform: 'scale(1.1)'
            }}
            transition="all 0.2s"
          />
        ))}
      </HStack>
    </BaseInput>
  );
} 